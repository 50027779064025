@use 'sass:map';
@use 'sass:math';
@use 'functions' as *;

// Turns an element into an arrow.
@mixin arrow-clip($direction) {
    clip-path: map.get(
        (
            up: polygon(50% 0, 100% 100%, 0 100%),
            right: polygon(0 0, 100% 50%, 0 100%),
            down: polygon(0 0, 100% 0, 50% 100%),
            left: polygon(0 50%, 100% 0, 100% 100%),
        ),
        $direction
    );
}

// A utility for border-radius.
$chamfer-sizes: (
    default: fluid(4, 8),
    large: fluid(4, 12),
);

@mixin chamfer($tl: 0, $tr: 0, $br: 0, $bl: 0, $size: 'default') {
    $size-value: $size;

    @if map.get($chamfer-sizes, $size) {
        $size-value: map.get($chamfer-sizes, $size);
    }

    @if $tl == 1 {
        border-top-left-radius: $size-value;
    } @else {
        border-top-left-radius: 0;
    }

    @if $tr == 1 {
        border-top-right-radius: $size-value;
    } @else {
        border-top-right-radius: 0;
    }

    @if $br == 1 {
        border-bottom-right-radius: $size-value;
    } @else {
        border-bottom-right-radius: 0;
    }

    @if $bl == 1 {
        border-bottom-left-radius: $size-value;
    } @else {
        border-bottom-left-radius: 0;
    }
}

// Blurs everything behind the element.
@mixin frosted-glass($strength: 1px) {
    backdrop-filter: blur($strength * 3);
}

// A utility for stacking elements.
@mixin stack($direction, $gap: -1, $gap-max: -1) {
    display: flex;

    @if $gap-max > 0 {
        gap: fluid($gap, $gap-max);
    } @else if $gap > 0 {
        gap: rem($gap);
    }

    @if $direction == 'v' {
        flex-flow: column;
    } @else if $direction == '>' {
        flex-flow: row;
    }
}

// A utility for applying a line limit to text
@mixin line-clamp($lines) {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: #{$lines};
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
}

// A utility for applying ratio to images
@mixin ratio($width, $height) {
    position: relative;
    max-width: rem($width);
    max-height: rem($height);
    font-size: 0;

    &::after {
        display: inline-block;
        padding-top: math.div($height, $width) * 100%;
        width: 100%;
        content: '';
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

// Fill the area of the parent.
@mixin fit($fit-type: cover, $fit-position: center) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @if $fit-position {
        object-position: $fit-position;
    }

    @if $fit-type {
        object-fit: $fit-type;
    }
}

/**
 * Generates padding classes for the section element
 */
@mixin padding-generator($direction, $size, $value) {
    .block {
        &.spacing-#{$direction}-#{$size} {
            padding-#{$direction}: $value !important;
        }
    }
}
