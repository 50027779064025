@use 'util' as *;

@include template('home') {
    .component-header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        &:not(.sticky) {
            .bottom {
                background: transparent;
                border-bottom: 1px solid rgba(#8F9EBA, 20%);
            }
        }
    }

    .inicio {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        min-height: fluid(400, 850);
        padding: 0;
        background: $primary;

        .video {
            @include fit;

            @media (min-aspect-ratio: 16/9) {
                height: 300%;
                top: -100%;
            }

            @media (max-aspect-ratio: 16/9) {
                width: 300%;
                left: -100%;
            }

            iframe {
                @include fit;

                pointer-events: none;
            }

            &::after {
                @include fit;

                content: '';
                background: url('../../src/assets/images/bg-body.png') $primary;
                opacity: 0.6;
            }
        }

        .slider-wrapper {
            display: grid;
            grid-template-columns: fluid(12, 17) minmax(0, 1fr) fluid(12, 17);
            gap: fluid(15, 40);
            position: relative;
            z-index: 3;
            padding: fluid(120, 200) fluid(20, 40) fluid(40, 80);

            .swiper {
                .slide {
                    .content {
                        max-width: rem(580);
                        margin: 0 auto;
                        color: $light;
                        text-align: center;

                        .title {
                            margin: 0;
                            font-size: fluid(26, 39);
                            font-weight: normal;
                            text-transform: unset;
                            letter-spacing: 0;

                            strong {
                                padding: 0 fluid(5, 10);
                                background: $primary;
                                font-weight: 800;
                                letter-spacing: fluid(2, 6);
                                text-transform: uppercase;
                            }
                        }

                        .cta {
                            margin-top: fluid(30, 75);
                        }
                    }
                }
            }
        }
    }

    .governanca {
        position: relative;
        overflow: hidden;
        padding: rem(40) 0 fluid(40, 120);

        .decoration {
            position: absolute;
            top: fluid(0, -30);
            right: 0;
            width: fluid(200, 540);
            color: #033066;
            font-size: fluid(40, 180);
            font-weight: 800;
            letter-spacing: fluid(2, 30);
            line-height: 0.9;
            text-align: right;
            text-transform: uppercase;
            word-break: break-all;
            opacity: 0.06;
        }

        .wrapper {
            position: relative;
            z-index: 2;
            max-width: rem(780);
            text-align: center;

            .arrow {
                width: rem(14);
                margin: 0 auto rem(20);
                color: $blue-light;
                text-align: center;

                svg {
                    height: fluid(60, 126);
                    margin: 0 auto;
                }
            }

            .title-wrapper {
                margin-bottom: fluid(20, 30);

                .title {
                    max-width: rem(340);
                    margin: 0 auto;
                    font-weight: normal;

                    strong {
                        font-weight: 800;
                        letter-spacing: fluid(2, 6);
                        text-transform: uppercase;
                        border-bottom: 2px solid $secondary;
                    }
                }
            }

            .text {
                p {
                    font-size: fluid(14, 16);
                    line-height: 1.4;
                }
            }

            .cta {
                margin-top: fluid(25, 40);

                .component-button {
                    min-width: fluid(180, 236);
                }
            }
        }
    }

    .section-veja_tambem {
        padding: fluid(40, 120) 0;

        &::before {
            height: 100%;
        }
    }

    .solucoes {
        position: relative;
        overflow: hidden;
        padding: fluid(40, 70) 0 fluid(40, 48);
        background: $light;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 45%;
            height: fluid(150, 250);
            background: $primary;
        }

        &::after {
            display: none;

            @include media('>=tablet') {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 44%;
                height: fluid(250, 485);
                background: $blue-light;
            }
        }

        .wrapper {
            position: relative;
            z-index: 2;

            .solucao {
                display: grid;
                grid-template-columns: 1fr;
                gap: fluid(30, 70);
                position: relative;
                z-index: 2;

                @include media('>=tablet') {
                    grid-template-columns: fluid(350, 450, 'tablet') 1fr;
                    align-items: center;
                }

                .image {
                    @include ratio(450, 515);

                    border-radius: 0 fluid(30, 65) 0 fluid(90, 190);
                    box-shadow: 0px 0px 13px 7px rgba(0, 0, 0, 0.10);
                    overflow: hidden;

                    img {
                        @include fit;
                    }
                }

                .content {
                    position: relative;
                    z-index: 2;

                    .top {
                        margin-bottom: fluid(20, 30);

                        @include media('>=tablet') {
                            margin-left: fluid(-60, -87);
                        }

                        .subtitulo {
                            font-size: rem(14);

                            @include media('>=tablet') {
                                padding-left: rem(35);
                            }
                        }

                        .title {
                            color: $tertiary;
                            font-size: fluid(26, 56);
                            text-transform: uppercase;
                            letter-spacing: fluid(2, 6);
                        }
                    }

                    .text {
                        p {
                            font-size: fluid(14, 16);
                            line-height: 1.45;
                        }
                    }

                    .cta {
                        margin-top: fluid(20, 40);
                    }
                }

                &.solucao-empresa {
                    margin-bottom: rem(40);

                    @include media('>=tablet') {
                        grid-template-columns: 1fr fluid(350, 450, 'tablet');
                        margin-bottom: rem(-30);
                    }

                    .image {
                        border-radius: 0 fluid(90, 190) 0 fluid(30, 65);

                        @include media('>=tablet') {
                            order: 2;
                        }
                    }

                    .content {
                        @include media('>=tablet') {
                            text-align: right;
                        }

                        .top {
                            @include media('>=tablet') {
                                margin-right: fluid(-60, -87);
                            }

                            .subtitulo {
                                @include media('>=tablet') {
                                    padding-right: rem(35);
                                }
                            }

                            .title {
                                color: $secondary;
                            }
                        }

                        .text {
                            p {
                                font-size: fluid(14, 16);
                                line-height: 1.45;
                            }
                        }
                    }
                }
            }
        }
    }

    .metodologia {
        position: relative;
        overflow: hidden;
        padding: rem(20) 0 fluid(50, 110);
        background: url('../../src/assets/images/bg-body.png') $gray-light;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: fluid(100, 180);
            background: $light;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 44%;
            height: fluid(120, 210);
            background: $blue-light;
        }

        .wrapper {
            display: grid;
            grid-template-columns: 1fr;
            gap: fluid(40, 100);
            position: relative;
            z-index: 2;
            max-width: rem(1260);

            @include media('>=tablet') {
                grid-template-columns: 1fr 1fr;
                align-items: flex-end;
            }

            .image-wrapper {
                @include media('>=tablet') {
                    order: 2;
                }
            }

            .dados {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: fluid(25, 35) fluid(25, 80);
                padding-bottom: rem(15);

                .dado {
                    display: grid;
                    grid-template-columns: 1fr;
                    gap: rem(5);

                    @include media('>=tablet') {
                        text-align: right;
                    }

                    .top {
                        .value {
                            font-size: fluid(36, 90);
                            font-weight: 800;
                            letter-spacing: fluid(2, 6);
                        }

                        .sufixo {
                            font-size: fluid(16, 36);
                        }
                    }

                    .info {
                        margin: 0;
                        font-size: fluid(14, 18);
                        line-height: 1.2;
                    }
                }
            }
        }

        .decoration {
            position: absolute;
            bottom: fluid(0, -15);
            left: 0;
            color: #464646;
            font-size: fluid(36, 130);
            font-weight: 800;
            line-height: 0.8;
            text-transform: uppercase;
            letter-spacing: fluid(2, 20);
            opacity: 0.06 !important;
        }
    }

    .blog {
        position: relative;
        padding: fluid(40, 60) 0 fluid(40, 90);
        background: $light;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            width: 100%;
            height: fluid(300, 500);
            max-height: 75%;
            background: $primary;
        }

        .wrapper {
            position: relative;
            z-index: 2;

            .title-wrapper {
                margin-bottom: fluid(20, 30);

                .title {
                    max-width: rem(500);
                    margin: 0 auto;
                    color: $light;
                    font-size: fluid(15, 23);
                    font-weight: normal;
                    text-align: center;

                    strong {
                        display: block;
                        color: $blue-light;
                        font-size: fluid(26, 40);
                        font-weight: 800;
                        letter-spacing: fluid(2, 6);
                        text-transform: uppercase;
                    }
                }
            }

            .text {
                max-width: rem(490);
                margin: 0 auto fluid(30, 60);
                font-size: fluid(15, 19);
                color: $light;
                text-align: center;
            }

            .posts {
                display: grid;
                grid-template-columns: 1fr;
                gap: fluid(15, 30);

                @include media('>=phone-large') {
                    grid-template-columns: repeat(2, 1fr);
                }

                @include media('>=tablet') {
                    grid-template-columns: repeat(3, 1fr);
                }

                .component-blog-destaque {
                    background: #EFEFEF;
                }
            }

            .cta-bottom {
                display: block;
                margin-top: fluid(30, 50);
                text-align: center;

                a {
                    color: $blue-light;
                    font-size: rem(11);
                    font-weight: 800;
                    text-decoration: underline;

                    &:hover {
                        color: $secondary;
                    }
                }
            }
        }
    }
}