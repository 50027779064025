// Transition timings
$fast: 250ms;
$slow: 500ms;
$smooth: 1000ms;
$static: 0s;
$ease: cubic-bezier(0.5, 0, 0, 1);

// Main Colors
$primary: #102D49;
$secondary: #8FD1BE;
$tertiary: #E59933;
$dark: #102D49;
$light: #FFFFFF;

// Colors
$blue: #033066;
$blue-dark: #102D49;
$blue-light: #8F9EBA;
$green: #8FD1BE;
$gray: #B2B2B2;
$gray-light: #D9D9D9;
$orange: #E59933;
$yellow: #FFE35A;
$ceo: #AD601A;
$conselho: #BA9F7F;